//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainChartExample from '../base/charts/MainChartExample'
import Vue from "vue";
import func from "@/utils/decoded";
import {fetchDashboardData} from "@/api/admin";

export default {
  name: 'Dashboard',
  components: {
    MainChartExample
  },
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
		if (Vue.prototype.$session.get('role') > 4) {
			this.$router.push({path: '/404'})
		}
	},
  data () {
    return {
			items: [],
      selected: 'Month',
      tableFields: [
        { key: 'avatar', label: '', _classes: 'text-center' },
        { key: 'user' },
        { key: 'country', _classes: 'text-center' },
        { key: 'usage' },
        { key: 'payment', label: 'Payment method', _classes: 'text-center' },
        { key: 'activity' },
      ]
    }
  },
	async mounted() {
		this.$store.state.loading = true
		try {
			//Loading dashboard data
			await new Promise((resolve, reject) => {
				fetchDashboardData(this.filterParams).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.items = data
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
		} finally {
			this.$store.state.loading = false
		}
		this.$store.state.loading = true

		const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
    this.$store.state.loading = false
	},
	methods: {
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
