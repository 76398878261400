//
//
//
//
//
//
//
//

import moment from "moment/moment";
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'MainChartExample',
  components: {
    CChartLine
  },
  props: {
    contract: null
  },
  data () {
    return {
      labels: [],
    }
  },
  mounted() {
    const current = new Date();
    const month = moment.monthsShort()
    let year = current.getFullYear() + 542
    for (let i = current.getMonth() + 1; i < 12; i++) {
      this.labels.push(month[i] + " " + (year))
    }
    year += 1
    for (let i = 0; i <= current.getMonth(); i++) {
      this.labels.push(month[i] + " " + year)
    }

  },
  computed: {
    defaultDatasets () {
      const current = new Date();
      let year = current.getFullYear() - 1

      const data1 = []
      if (this.contract) {
        for (let i = current.getMonth() + 1; i < 12; i++) {
          const result = this.contract.find(item => item.contract_year === year && item.contract_month === i+1);
          if (result) {
            data1.push(result.contract_count)
          } else{
            data1.push(0)
          }
        }
        year += 1
        for (let i = 0; i <= current.getMonth(); i++) {
          const result = this.contract.find(item => item.contract_year === year && item.contract_month === i+1);
          if (result) {
            data1.push(result.contract_count)
          } else{
            data1.push(0)
          }
        }

        const brandInfo = getStyle('info') || '#20a8d8'
        return [
          {
            label: 'จำนวนสัญญา',
            backgroundColor: hexToRgba(brandInfo, 10),
            borderColor: brandInfo,
            pointHoverBackgroundColor: brandInfo,
            borderWidth: 2,
            data: data1
          }
        ]
      } else {
        return []
      }
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 7,
              stepSize: Math.ceil(200 / 5),
              max: 1000
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
